import React from 'react';
import XLSX from 'xlsx';
import {
    Row,
    Col,
    Button,
    Icon, Result,
} from 'antd';
import {NavLink} from "react-router-dom";

import {Layout, Menu, Breadcrumb} from 'antd';
import logo from '../../logo.png';
import {
    Input,
    InputGroup,
    InputGroupAddon,
    Container,
    Card
} from 'reactstrap';
import {AgGridReact} from "ag-grid-react";
import Profile from "../Profile/Profile";
import Cookies from "js-cookie";
import axios from "axios";
import { API_URL, CONSIGNMENTS_URL } from '../../config'

const {Content, Footer, Sider} = Layout;
let today = new Date();


let yyyy = today.getFullYear();
let user
if (Cookies.get("username")) {
    //console.log(Cookies.get("username") + 'username')
    //user = JSON.parse(Cookies.get("username")).username
    // Router.push("/");
    //console.log(user)
}

let state = {
    rows: {}
}

function parseDate(date) {
    let dd = date.getDate();
    if (dd < 10) dd = '0' + dd;
    let mm = date.getMonth() + 1;
    if (mm < 10) mm = '0' + mm;
    let yy = date.getFullYear();
    //if (yy < 10) yy = '0' + yy;
    return dd + '.' + mm + '.' + yy;
}

function checkDate(item) {
    if (item && item.length < 9 && item.length > 2) {
        //let test = Date.parse(item[1]) / 1000//.getTime()

        let tes = parseDate(new Date(Date.parse(item)));
        //console.log(item.length)
        //console.log(tes)
        //console.log(typeof item)
        //console.log(tes)
        return (
            tes
        )
    } else {
        return item
    }
}

class Consignment extends React.Component {
    state = {
        collapsed: false,
    };

    onCollapse = collapsed => {
        //console.log(collapsed);
        this.setState({collapsed});
    };


    constructor(props) {
        super(props);
        this.state = {
            isOpen: false,
            dataLoaded: false,
            uploaded: false,
            isFormInvalid: false,
            rows: null,
            cols: null,
            loading: false,
            columnDefs: [{
                headerName: "Номер товарной партии", field: "consNum"
            }, {
                headerName: "Отправка транспортного средства от поставщика - прогноз", field: "delivCustomEst"
            }, {
                headerName: "Отправка транспортного средства от поставщика - факт", field: "delivCustomFact"
            }, {
                headerName: "Причина изменения даты/Комментарий на звене", field: "delivCustomFactChangeReason"
            }, {
                headerName: "Доставка партии в порт - прогноз", field: "delivPortEst"
            }, {
                headerName: "Доставка партии в порт - факт", field: "delivPortFact"
            }, {
                headerName: "Отправка партии из порта на ТО - прогноз", field: "sendPartTOEst"
            }, {
                headerName: "Отправка партии из порта на ТО - факт", field: "sendPartTOFact"
            }, {
                headerName: "Доставка партии на таможенный терминал РФ - прогноз", field: "delivCustomTerminalEst"
            }, {
                headerName: "Доставка партии на таможенный терминал РФ - факт", field: "delivCustomTerminalFact"
            }, {
                headerName: "Причина изменения даты/Комментарий на звене", field: "delivCustomTerminalFactChangeReason"
            }, {
                headerName: "Начало ТО партии - факт", field: "startToFactFact"
            }, {
                headerName: "Окончание ТО партии - факт", field: "endToFactFact"
            }, {
                headerName: "Отправка партии по России - прогноз", field: "sendDomesticEst"
            }, {
                headerName: "Отправка партии по России - факт", field: "sendDomesticFact"
            }, {
                headerName: "Причина изменения даты/Комментарий на звене", field: "sendDomesticFactChangeReason"
            }, {
                headerName: "Прибытие партии на склад иэк – прогноз экспертный", field: "PartyArrivalEst"
            }, {
                headerName: "Номер коносамента", field: "cononsNo"
            }],
        };
        this.fileHandler = this.fileHandler.bind(this);
        this.toggle = this.toggle.bind(this);
        this.openFileBrowser = this.openFileBrowser.bind(this);
        this.uploadData = this.uploadData.bind(this);
        this.uploadAgain = this.uploadAgain.bind(this);
        this.renderFile = this.renderFile.bind(this);
        //this.openNewPage = this.openNewPage.bind(this);
        this.fileInput = React.createRef();
    }

    renderFile = (fileObj) => {


        /* Boilerplate to set up FileReader */
        const reader = new FileReader();
        const rABS = !!reader.readAsBinaryString;
        reader.onload = (e) => {
            /* Parse data */
            const bstr = e.target.result;
            const wb = XLSX.read(bstr, {type: rABS ? 'binary' : 'array', cellDates: true});
            /* Get first worksheet */
            const wsname = wb.SheetNames[0];
            const ws = wb.Sheets[wsname];
            /* Convert array of arrays */
            const data = XLSX.utils.sheet_to_json(ws, {header: 1});
            /* Update state */
            let rowdata = [];
            data.map(function (item) {

                let row = {
                    consNum: item[0],
                    delivCustomEst: checkDate(item[1]),
                    delivCustomFact: checkDate(item[2]),
                    delivCustomFactChangeReason: item[3],
                    delivPortEst: checkDate(item[4]),
                    delivPortFact: checkDate(item[5]),
                    sendPartTOEst: checkDate(item[6]),
                    sendPartTOFact: checkDate(item[7]),
                    delivCustomTerminalEst: checkDate(item[8]),
                    delivCustomTerminalFact: checkDate(item[9]),
                    delivCustomTerminalFactChangeReason: item[10],
                    startToFactFact: checkDate(item[11]),
                    endToFactFact: checkDate(item[12]),
                    sendDomesticEst: checkDate(item[13]),
                    sendDomesticFact: checkDate(item[14]),
                    sendDomesticFactChangeReason: item[15],
                    PartyArrivalEst: checkDate(item[16]),
                    cononsNo: item[17],
                };
                // if (!item[14]) {
                //     notification.warning({
                //         message: 'Ошибка',
                //         description:
                //             'Заполните номер коносамента для товарной партии №' + item[0] + '. Данная товарная партия не будет загружена для обработки.',
                //     });
                // } else {
                //     rowdata.push(row)
                // }
                rowdata.push(row)
                //console.log(item)
                return row;
            });

            delete rowdata[0];
            state.rows = rowdata
            this.setState({
                dataLoaded: true,
                rowData: rowdata
            });
        };
        if (rABS) reader.readAsBinaryString(fileObj); else reader.readAsArrayBuffer(fileObj);
    };

    fileHandler = (event) => {
        if (event.target.files.length) {
            let fileObj = event.target.files[0];
            let fileName = fileObj.name;


            //check for file extension and pass only if it is .xlsx and display error message otherwise
            if (fileName.slice(["xls", "xlsx"].includes(fileName.lastIndexOf('.') + 1))) {
                this.setState({
                    uploadedFileName: fileName,
                    isFormInvalid: false
                });
                this.renderFile(fileObj)
            } else {
                this.setState({
                    isFormInvalid: true,
                    uploadedFileName: ""
                })
            }
        }
    };

    toggle() {
        this.setState({
            isOpen: !this.state.isOpen
        });
    }

    openFileBrowser = () => {
        this.fileInput.current.click();
        this.setState({
            uploaded: false,
        });
    };
    uploadData = () => {
        //console.log(state.rows)
        let expeditorId = ''
        expeditorId = Cookies.get("jwt")
        axios.post(`${API_URL}${CONSIGNMENTS_URL}`, {
            query: "updatelist",
            expeditorId: expeditorId,
            data: state.rows
        })

        this.setState({
            dataLoaded: false,
            uploaded: true,
        });
    };
    uploadAgain = () => {
        this.setState({
            dataLoaded: false,
            uploaded: false,
            loading: false
        });
    };
    enterLoading = () => {
        this.setState({loading: true});
    };

    render() {


        return (
            <Layout style={{minHeight: '100vh'}}>
                <Sider collapsible collapsed={this.state.collapsed} onCollapse={this.onCollapse}>
                    <div className="logo"><img src={logo} alt="logo"/></div>
                    <Menu theme="dark" defaultSelectedKeys={['2']} mode="inline">
                        <Menu.Item key="1"><NavLink to="/consignment">
                            <Icon type="unordered-list"/>
                            <span>Товарные партии</span></NavLink>
                        </Menu.Item>
                        <Menu.Item key="2"><NavLink to="/upload">
                            <Icon type="download"/>
                            <span>Загрузка обновлений</span></NavLink>
                        </Menu.Item>
                        <Menu.Item key="3"><NavLink to="/history">
                            <Icon type="history"/>
                            <span>История обновлений</span></NavLink>
                        </Menu.Item>
                    </Menu>
                </Sider>

                <Layout>

                    <Content style={{margin: '0 16px'}}>
                        <Row type="flex" justify="space-between">

                            <Breadcrumb style={{margin: '16px 0'}}>
                                <Breadcrumb.Item>Портал экспедиторов</Breadcrumb.Item>
                                <Breadcrumb.Item>Загрузка обновлений</Breadcrumb.Item>
                            </Breadcrumb>
                            <Profile username={user}/>

                        </Row>
                        <div>

                            <Container>

                                <InputGroup>
                                    <InputGroupAddon addonType="prepend">
                                        <Button type="dashed" onClick={this.openFileBrowser.bind(this)}>Загрузить
                                            файл</Button>
                                        <input className={'hidden'} style={{display: 'none', "padding": "10px"}} type="file" hidden
                                               onChange={this.fileHandler.bind(this)} ref={this.fileInput}
                                               onClick={(event) => {
                                                   event.target.value = null
                                               }} />
                                    </InputGroupAddon>
                                    <Input type="text" className="form-control" hidden
                                           value={this.state.uploadedFileName} readOnly
                                           invalid={this.state.isFormInvalid}/>
                                </InputGroup>


                                {this.state.dataLoaded &&
                                <div>
                                    <Card body outline color="secondary" className="restrict-card">
                                        <Row style={{marginTop: 25}} type="flex" justify="start">
                                            <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                                                <div
                                                    className="ag-theme-material"
                                                    style={{

                                                        width: '100%'
                                                    }}
                                                >
                                                    <AgGridReact
                                                        columnDefs={this.state.columnDefs}
                                                        rowData={state.rows}>
                                                    </AgGridReact>
                                                </div>
                                                <Button loading={this.state.loading} onClick={() => {
                                                    this.enterLoading();
                                                    this.uploadData()
                                                }} type="primary" style={{marginTop: 20}}>Подтвердить и
                                                    отправить</Button>


                                            </Col>

                                        </Row>
                                    </Card>
                                </div>}
                                {this.state.uploaded &&
                                <Result
                                    status="success"
                                    title="Данные загружены!"
                                    subTitle=""
                                    extra={[
                                        <NavLink to={'/consignment'}><Button type="primary" key="console">
                                            Товарные партии
                                        </Button></NavLink>,
                                        <Button onClick={this.uploadAgain.bind(this)} key="buy">Загрузить еще</Button>,
                                    ]}
                                />}
                            </Container>
                        </div>


                    </Content>
                    <Footer style={{textAlign: 'center'}}>Группа компаний IEK © 1999—{yyyy}. Все права
                        защищены.</Footer>
                </Layout>
            </Layout>
        );
    }
}

export default Consignment;
